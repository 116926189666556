import React from "react"
import Layout from "../layout"

// import "../../styles/h/layout.css"

const HLayout = ({ children }) => (
  <Layout>
    <>
      <div class="lines">
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
      {children}
    </>
  </Layout>
)

export default HLayout
