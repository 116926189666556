import React from "react"
import Layout from "../../components/h/layout"

const HalloweenGPage = () => (
  <Layout>
    <h2>When we bought this, you sat on the car floor.</h2>
    <h2>You use this to play dj hero and more.</h2>
  </Layout>
)

export default HalloweenGPage
